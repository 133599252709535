import TurbolinksAdapter from "vue-turbolinks";
import ActionCableVue from "actioncable-vue";
import Vue from "vue/dist/vue.esm"
import App from "../app.vue"
// import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker"
import "vue-airbnb-style-datepicker/dist/styles.css"
import { EmojiPickerPlugin } from "vue-emoji-picker"
// import VModal from "vue-js-modal"
import VModal from "../components/forks/vue-js-modal"
import linkify from "vue-linkify"
import Raters from "vue-rate-it"
import DropdownDatepicker from "vue-dropdown-datepicker";
import "@hotwired/turbo-rails"

import ScrollLoader from "vue-scroll-loader"
Vue.use(ScrollLoader)

import "pretty-checkbox/src/pretty-checkbox.scss"

Vue.directive("linkified", linkify)
Vue.use(TurbolinksAdapter)
Vue.use(EmojiPickerPlugin)

import axios from "axios"
import VueMq from "vue-mq"

import VueFormWizard from "vue-form-wizard"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
Vue.use(VueFormWizard)

import VueFormGenerator from "vue-form-generator"
import "vue-form-generator/dist/vfg.css";

import AvatarCropper from "../components/forks/avatar_cropper/show.vue"
Vue.component("avatar-cropper", AvatarCropper)

import StepProgress from "../components/forks/vue_step_progress/show.vue"
Vue.component("step-progress", StepProgress)

import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faQuestionCircle,
  faEnvelope,
  faCalendarAlt,
  faCalendarPlus,
  faCog,
  faCircle,
  faPlus,
  faMapMarkerAlt,
  faArrowLeft,
  faComment,
  faCommentDots,
  faPaperPlane,
  faSignOutAlt,
  faInfoCircle,
  faUserAlt,
  faUsers,
  faComments,
  faMessage,
  faCheck,
  faPlusCircle,
  faCogs,
  faExclamationCircle,
  faArrowUp,
  faArrowDown,
  faChartLine,
  faRedo,
  faSearch,
  faLockOpen,
  faLock,
  faUnlock,
  faUserClock,
  faClock,
  faCheckSquare,
  faEdit,
  faUserPlus,
  faLightbulb,
  faArrowCircleRight,
  faCheckCircle,
  faChevronRight,
  faTimesCircle,
  faFrown,
  faThumbsUp,
  faLink,
  faUser,
  faHome,
  faMapPin,
  faBan,
} from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

const Slideout = require("slideout")

library.add(
  faQuestionCircle, faEnvelope, faCalendarAlt, faCog, faPlus, faRedo,
  faMapMarkerAlt, faArrowLeft, faComment, faPaperPlane,
  faCalendarPlus, faSignOutAlt, faInfoCircle, faUserAlt,
  faComments, faCheck, faPlusCircle, faCogs, faUsers, faExclamationCircle,
  faArrowUp, faArrowDown, faChartLine, faCommentDots, faSearch, faLockOpen,
  faLock, faUnlock, faUserClock, faCheckSquare, faEdit, faUserPlus, faLightbulb,
  faArrowCircleRight, faCheckCircle, faFrown, faLink, faChevronRight, faClock,
  faTimesCircle, faUser, faHome, faThumbsUp,faMapPin, faCircle, faBan
)

import Header from "../components/header/show.vue"
import HeaderMobile from "../components/header/show_mobile.vue"
import HeaderDesktop from "../components/header/show_desktop.vue"
import SideMenu from "../components/header/side_menu.vue"

import SportsList from "../components/sports/index.vue"
import SportItem from "../components/sports/show.vue"
import UserSportScheduleFormItem from "../components/user_sport_schedules_form/show.vue"
import UserSportScheduleFormList from "../components/user_sport_schedules_form/index.vue"

import SportEventsList from "../components/sport_events/index.vue"
import SportEventListItem from "../components/sport_events/index_item.vue"
import SportEventMinimalListItem from "../components/sport_events/minimal_index_item.vue"
import SportEventItem from "../components/sport_events/show.vue"
import SportEventPartners from "../components/sport_events/partners.vue"
import SportEventReview from "../components/sport_events/review.vue"
import SportEventPlaceInfo from "../components/sport_events/place_info.vue"
import SportEventForm from "../components/sport_events/form.vue"
import SportEventEditForm from "../components/sport_events/edit.vue"
import SportEventStats from "../components/sport_events/stats.vue"
import SportEventNewAccountNotReady from "../components/sport_events/account_not_ready_for_event_creation.vue"
import UserSportEventsList from "../components/sport_events/user_index.vue"
import SportEventCompetitionNewForm from "../components/sport_competitions/new_form.vue"
import SportEventCompetitionList from "../components/sport_competitions/index.vue"
import SportEventCompetition from "../components/sport_competitions/show.vue"
import SportCompetitionResultSubmission from "../components/sport_competitions/result_submition.vue"

import UserProfile from "../components/users/show.vue"
import CurrentUserCompetitions from "../components/users/competitions/current_user_block.vue"
import CurrentUserInProgressCompetition from "../components/users/competitions/in_progress_item.vue"
import CurrentUserFinishedCompetition from "../components/users/competitions/finished_item.vue"
import CurrentUserCompetitionsYearResults from "../components/users/competitions/year_result_small.vue"
import UsersList from "../components/users/index.vue"
import LatestSignedUpUsersList from "../components/users/latest_signed_up.vue"
import UserEditProfile from "../components/users/edit.vue"
import UserSportList from "../components/users/sports.vue"
import UserPartnersList from "../components/users/partners.vue"
import InviteUser from "../components/users/invite.vue"
import UserListAvatar from "../components/users/helpers/list_avatar.vue"
import UserAvatar from "../components/users/helpers/user_avatar.vue"

import ChatsList from "../components/chats/index.vue"
import SportEventChat from "../components/sport_event_chat/show.vue"
import ChatMessages from "../components/chats/show.vue"
import ChatMessage from "../components/chat_message/show.vue"
import FooterNav from "../components/footer/show.vue"

import TopTabs from "../components/tabs/top_tabs.vue"

import EmailConfirmationAlert from "../components/alerts/email_confirmation_alert.vue"

import FirstRegistrationStep from "../components/registration_steps/first_step.vue"
import SecondRegistrationStep from "../components/registration_steps/second_step.vue"
import ThirdRegistrationStep from "../components/registration_steps/third_step.vue"
import FourthRegistrationStep from "../components/registration_steps/fourth_step.vue"

Vue.config.ignoredElements = ["action-text-attachment"]

Vue.component("image-rating", Raters.ImageRating)
Vue.component("dropdown-datepicker", DropdownDatepicker)

Vue.component("font-awesome-icon", FontAwesomeIcon)

Vue.config.productionTip = false

Vue.component("vue-form-generator", VueFormGenerator.component)

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: process.env.RAILS_ENV == "development" ? "all" : "error" ,
  connectionUrl: process.env.RAILS_ENV == "development" ? "ws://lvh.me:3000/cable" : "wss://spottyspo.com/cable"
})

Vue.use(VueMq, {
  breakpoints: {
    sm: 600,
    md: 1250,
    lg: Infinity,
  }
})

Vue.component("app", App)

Vue.component("header-nav", Header)
Vue.component("header-nav-mobile", HeaderMobile)
Vue.component("header-nav-desktop", HeaderDesktop)
Vue.component("side-menu", SideMenu)

import Notifications from "../components/notifications/index.vue"

Vue.component("notifications", Notifications)

// Use require.context to dynamically import all components in the folder
const context = require.context('../components/notifications/by_event', false, /\.vue$/);

context.keys().forEach((filename) => {
  // Get the component config
  const componentConfig = context(filename);

  // Extract the component name from the filename
  const componentName = filename
    .split('/') // Split the file path
    .pop() // Get the filename
    .replace(/\.\w+$/, '') // Remove the file extension
    .replace(/_/g, '-') // Convert snake_case to kebab-case
    .toLowerCase(); // Ensure the name is in lowercase

  // Register the component globally
  Vue.component(`${componentName}-notification`, componentConfig.default || componentConfig);
});

Vue.component("sport-event-account-not-ready-for-event-creation", SportEventNewAccountNotReady)

Vue.component("sports-list", SportsList)
Vue.component("sport-item", SportItem)
Vue.component("user-sport-schedules-form-list", UserSportScheduleFormList)
Vue.component("user-sport-schedules-form-item", UserSportScheduleFormItem)

Vue.component("events", SportEventsList)
Vue.component("user-events", UserSportEventsList)
Vue.component("event-list-item", SportEventListItem)
Vue.component("minimal-event-list-item", SportEventMinimalListItem)
Vue.component("event-partners", SportEventPartners)
Vue.component("event-review", SportEventReview)
Vue.component("event-place-info", SportEventPlaceInfo)
Vue.component("event-item", SportEventItem)
Vue.component("event-item-form", SportEventForm)
Vue.component("event-item-edit-form", SportEventEditForm)
Vue.component("event-stats", SportEventStats)

Vue.component("new-sport-competition", SportEventCompetitionNewForm)
Vue.component("sport-competition-list", SportEventCompetitionList)
Vue.component("sport-competition", SportEventCompetition)
Vue.component("sport-competition-result-submission", SportCompetitionResultSubmission)

Vue.component("user-profile", UserProfile)
Vue.component("current-user-competitions", CurrentUserCompetitions)
Vue.component("current-user-in-progress-competition", CurrentUserInProgressCompetition)
Vue.component("current-user-finished-competition", CurrentUserFinishedCompetition)
Vue.component("current-user-competitions-year-result", CurrentUserCompetitionsYearResults)
Vue.component("users-list", UsersList)
Vue.component("latest-users-list", LatestSignedUpUsersList)
Vue.component("user-edit-profile", UserEditProfile)
Vue.component("user-sport-list", UserSportList)
Vue.component("user-partners-list", UserPartnersList)
Vue.component("invite-user", InviteUser)
Vue.component("user-list-avatar", UserListAvatar)
Vue.component("user-avatar", UserAvatar)

Vue.component("chats-list", ChatsList)
Vue.component("sport-event-chat", SportEventChat)
// duplicate sport-event-chat
Vue.component("chat-messages", ChatMessages)
Vue.component("chat-message", ChatMessage)

Vue.component("first-registration-step", FirstRegistrationStep)
Vue.component("second-registration-step", SecondRegistrationStep)
Vue.component("third-registration-step", ThirdRegistrationStep)
Vue.component("fourth-registration-step", FourthRegistrationStep)

Vue.component("top-tabs", TopTabs)
Vue.component("footer-nav", FooterNav)

Vue.component("email-confirmation-alert", EmailConfirmationAlert)

function loadFormGeneratorErrorMessages() {
  // load translation for validator error messages
  if (I18n.locale == "en") {
    return
  }

  const res = VueFormGenerator.validators.resources

  Object.keys(res).forEach((el) => {
    res[el] = I18n.t(`vue_form_generator.errors.${el}`)
  })
}

document.addEventListener("turbo:load", () => {
  // hack for turbolinks and slideout
  document.querySelector("html").className = ""
})

document.addEventListener("turbo:load", () => {
  Vue.prototype.CurrentUserId = $("body").data("user-id")
  axios.defaults.headers.common["X-CSRF-Token"] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

  I18n.locale = $("body").data("locale")

  Vue.prototype.I18n = I18n

  Vue.prototype.$eventHub = new Vue();

  // see docs for available options
  // const datepickerOptions = {
  //   days: I18n.t("date.day_names"),
  //   daysShort: I18n.t("date.default_abbr_day_names"),
  //   monthNames: I18n.t("date.default_month_names"),
  // }

  // // make sure we can use it in our components
  // Vue.use(AirbnbStyleDatepicker, datepickerOptions)

  // we don't invoke Vue.use because its executed only onces and this not working with turbolinks
  // (it's not recreated components from install method)
  VModal.install(Vue, { dynamic: true })

  const app = new Vue({
    el: '[data-behavior="vue"]',
  })

  $('[data-toggle="tooltip"]').tooltip()

  loadFormGeneratorErrorMessages()

  if (document.getElementById('panel') && document.getElementById('menu')) {
    const slideout = new Slideout({
      'panel': document.getElementById('panel'),
      'menu': document.getElementById('menu'),
      'padding': 256,
      'duration': 300,
      'tolerance': 70,
      'easing': 'cubic-bezier(.32,2,.55,.27)',
      'touch': false
    })

    if (document.querySelector('.toggle-button')) {
      // Toggle button
      document.querySelector('.toggle-button').addEventListener('click', function() {
        slideout.toggle()
      })
    }

    const fixed = document.querySelector('.fixed-header');

    slideout.on('translate', function(translated) {
      fixed.style.transform = 'translateX(' + translated + 'px)';
    });

    slideout.on('beforeopen', function () {
      fixed.style.transition = 'transform 300ms ease';
      fixed.style.transform = 'translateX(256px)';
    });

    slideout.on('beforeclose', function () {
      fixed.style.transition = 'transform 300ms ease';
      fixed.style.transform = 'translateX(0px)';
    });

    slideout.on('open', function () {
      fixed.style.transition = '';
    });

    slideout.on('close', function () {
      fixed.style.transition = '';
    });

    // document.querySelector('.menu').addEventListener('click', function(eve) {
    //   if (eve.target.nodeName === 'A') { slideout.close(); }
    // })
  }
})
