<template>
  <a :href="this.notification.data.profile_url" class="notification-item-wrap notification-item-wrap_ready">
    <div class="event-name">
      <a :href="this.notification.data.profile_url">
        {{ I18n.t("notifications.user_play_status_changed.database.title") }}
      </a>
    </div>

    <div class="notification-item">
      <div class="notification-item-message">
        <span class="notification-item-img">
          <img :src="this.notification.data.profile_image" loading="lazy" v-if="!!this.notification.data.profile_image">
          <span v-else class="nickname">@{{ this.notification.data.profile_nickname }}</span>
        </span>
        <span class="notification-item-img-msg" v-html="this.body()">
        </span>
      </div>
    </div>
  </a>
</template>

<script>
  export default {
    props: {
      notification: Object,
    },
    data() {
      return {
      }
    },
    methods: {
      body() {
        return I18n.t(
          "notifications.user_play_status_changed.database.text",
          {
            new_status: I18n.t(`users.play_status.${this.notification.data.new_play_status}`),
            nickname: this.notification.data.profile_nickname
          }
        )
      }
    }
  }
</script>

<style scoped>
</style>
